import React from 'react';
import Header from './component/Header'
import Nav from './component/Nav'
// import HomeFeatured from './component/HomeFeatured'

const App = () => {
  return (
    <div className='container'>
      <Header/>
      <Nav/>
      {/* <HomeFeatured/> */}
    </div>
  )
}

export default App;
