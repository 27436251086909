import React from 'react'
// import {
//   Switch,
//   Route,
//   Link,
//   useParams,
//   useRouteMatch
// } from 'react-router-dom'

const religonPage = () => (
  <p>Religon</p>

)

export default religonPage