import React from 'react'
import {
  Link,
  Switch,
  Route
} from 'react-router-dom'
import ReligionPage from './pages/ReligionPage'
import BookClub from './pages/BookClub'
import Technology from './pages/Technology'
import Sports from './pages/Sports'
import Drugs from './pages/Drugs'
import Finance from './pages/Finance'
import MentalHealth from './pages/MentalHealth'
import HomeFeatured from './HomeFeatured'

const nav = () => (
  <div class="nav-scroller py-1 mb-2">
    <nav class="nav d-flex justify-content-between">
      <Link to='/bookclub' className="p-2 text-muted" >Book Club</Link>
      <Link to='/religion' className="p-2 text-muted" >Religion</Link>
      <Link to='/technology' className="p-2 text-muted" >Technology</Link>
      <Link to='/sports' className="p-2 text-muted" >Sports</Link>
      <Link to='/drugs' className="p-2 text-muted" >Drugs</Link>
      <Link to='/finance' className="p-2 text-muted" >Finance</Link>
      {/* <Link to='/politics' className="p-2 text-muted" >Politics</Link> */}
      <Link to='/mentalhealth' className="p-2 text-muted" >Mental Health</Link>
      <Link to='/random' className="p-2 text-muted" >Random</Link>
      {/* <Link to='/games' className="p-2 text-muted" >Games</Link> */}
      {/* <a class="p-2 text-muted" href="#">Style</a> */}
      {/* <a class="p-2 text-muted" href="#">Travel</a> */}
    </nav>
    <Switch>
      <Route exact path='/'>
        <HomeFeatured/>
      </Route>
      <Route path='/religion'>
        <ReligionPage/>
      </Route>
      <Route path='/bookclub'>
        <BookClub/>
      </Route>
      <Route path='/technology'>
        <Technology/>
      </Route>
      <Route path='/sports'>
        <Sports/>
      </Route>
      <Route path='/drugs'>
        <Drugs/>
      </Route>
      <Route path='/finance'>
        <Finance/>
      </Route>
      <Route path='/mentalhealth'>
        <MentalHealth/>
      </Route>
    </Switch>
  </div>
)

export default nav