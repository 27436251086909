import React from 'react'
import Featured from './Featured'
import SubFeatured from './SubFeatured'


const homeFeatured = () => (
  <>
    <Featured/>
    <SubFeatured/>
  </>
)

export default homeFeatured