import React from 'react'
import img from '../bhlFirstDraft.jpg'
import {Link} from 'react-router-dom'

const header = () => (
    <header class="blog-header py-3">
    <div class="row flex-nowrap justify-content-between align-items-center">
      <div class="col-4 pt-1">
        <img src={img} alt='BHL logo' style={{width: '15%'}}/>
      </div>
      <div class="col-4 text-center">
        <Link to='/' className="blog-header-logo text-dark" style={{fontsize: '1.5rem'}}>B H L</Link>
      </div>
      <div class="col-4 d-flex justify-content-end align-items-center">
        <a class="text-muted" aria-label="Search">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="mx-3" role="img" viewBox="0 0 24 24" focusable="false"><title>Search</title><circle cx="10.5" cy="10.5" r="7.5"></circle><path d="M21 21l-5.2-5.2"></path></svg>
        </a>
        <a class="btn btn-sm btn-outline-secondary">Sign up</a>
      </div>
    </div>
  </header>
)


export default header